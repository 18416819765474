import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @Input() data;
  public isActive = false;

  constructor() {}

  toggleAccordian(event) {
    const element = event.target;
    element.classList.toggle('active');

    if (this.isActive) {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
    const panel = element.parentElement.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }
}
