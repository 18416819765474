import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { AgmCoreModule } from '@agm/core';
import {
  SwiperModule,
  SWIPER_CONFIG,
  SwiperConfigInterface,
} from 'ngx-swiper-wrapper';

import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';

import { RecaptchaModule } from 'angular-google-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';

import { ComponentsModule } from './components/components.module';
import { AnimateModule } from './animate/animate.module';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { RouterModule } from '@angular/router';
import { InternaViajeComponent } from './pages/interna-viaje/interna-viaje.component';
import { SlugDirective } from './directives/slug.directive';
import { MatInputModule } from '@angular/material/input';
import { HtmlPipe } from './components/inner-html/inner-html.component';

// import { CdkOverlayContainerDirective } from './helpers/cdk-overlay-container.directive';
import { TutoqueComponent } from './pages/tutoque/tutoque.component';
import { Viajes360Component } from './pages/viajes360/viajes360.component';
import { BusquedaComponent } from './pages/busqueda/busqueda.component';
import { RifasComponent } from './pages/rifas/rifas.component';
import { ListViajeComponent } from './components/list-viaje/list-viaje.component';
import { GlobalConstants } from './common/global-constants';
import { AppShellRenderDirective } from './directives/app-shell-render.directive';
import { AppShellNoRenderDirective } from './directives/app-shell-no-render.directive';

/**** IMPORTACIONES DE COMPONENTES MATERIAL ***/
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgVarDirective } from './directives/ng-var.directive';
import { PrivacidadComponent } from './pages/privacidad/privacidad.component';

/**** IMPORTACIONES DE COMPONENTES PRIME NG ***/
//import { ScrollPanelModule } from 'primeng/scrollpanel';
//import {ScrollerModule} from 'primeng/scroller';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 1.3,
  centeredSlides: false,
  spaceBetween: 10,
  slidesOffsetAfter: 100,
  observer: true,
  breakpoints: {
    640: {
      slidesPerView: 1.3,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 4.1,
      spaceBetween: 20,
      centeredSlides: false,
    },
  },
  navigation: {
    nextEl: '.swiper-custom-next',
    prevEl: '.swiper-custom-prev',
  },
  pagination: true,
};

@NgModule({
  declarations: [
    AppComponent,
    HtmlPipe,
    HomeComponent,
    CalendarComponent,
    PrivacidadComponent,
    TutoqueComponent,
    InternaViajeComponent,
    SlugDirective,
    NgVarDirective,
    Viajes360Component,
    BusquedaComponent,
    RifasComponent,
    AppShellRenderDirective,
    AppShellNoRenderDirective,
    ListViajeComponent
    // CdkOverlayContaisnerDirective
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    ComponentsModule,
    AnimateModule,
    RouterModule,
    HttpClientModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule,
    ScrollingModule,
    // ListViajeComponent,
    //NgxGoogleAnalyticsModule.forRoot('UA-54212002-1'),
    // Seiji GoogleAnalyticsG-Z52Q46VP23
    //NgxGoogleAnalyticsModule.forRoot('G-YP780B4865'),
    // TOQUEyTOQUE GoogleAnalytics
    NgxGoogleAnalyticsModule.forRoot('G-Z52Q46VP23'),
    NgxGoogleAnalyticsRouterModule.forRoot(),
    RecaptchaModule.forRoot({

      //clave 1 (seiji myRecaptcha): 6LdTmwgoAAAAANAkn6ZWQlhFEhiHSe_3nWtmsFQr

      //clave 2 (seiji myRecaptcha): 6LdTmwgoAAAAAFSOlUn3VXldBptqZFF27yxVbF85

      //dev
      // siteKey: '6LfSD80ZAAAAABfPA4pOKs6BZ-Myngmxkyg1pXja',
      //prod (old)
      //siteKey: '6Lc0N7UaAAAAALlLzsWy03utt0odeBuqFGMFmbQm',
      //prod (old)
      siteKey: '6LdTmwgoAAAAANAkn6ZWQlhFEhiHSe_3nWtmsFQr',

    }),
  ],
  exports: [
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    SlugDirective,
    AppShellRenderDirective,
    AppShellNoRenderDirective
    // CdkOverlayContainerDirective
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    // {
    //   provide: SWIPER_CONFIG,
    //   useValue: DEFAULT_SWIPER_CONFIG,
    // },
    GlobalConstants,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
