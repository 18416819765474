import { Component, Input } from '@angular/core';
import { ImageInfo } from '../../interfaces/image-info.interface';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  @Input() title: string;
  @Input() description: string;
  @Input() color: string;
  @Input() cardimg: ImageInfo;

  constructor() { }
}
