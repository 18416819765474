import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MainBannerComponent } from './main-banner/main-banner.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { CardComponent } from './card/card.component';
import { AnimateModule } from '../animate/animate.module';
import { FooterComponent } from './footer/footer.component';
import { TopFullBannerComponent } from './top-full-banner/top-full-banner.component';
import { RouterModule } from '@angular/router';
import { TopFullBannerSlideComponent } from './top-full-banner-slide/top-full-banner-slide.component';
import { TopFullBannerRifasComponent } from './top-full-banner-rifas/top-full-banner-rifas.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { AgmCoreModule } from '@agm/core';
import { PaqueteExpComponent } from './paquete-exp/paquete-exp.component';
import { InfoFooterComponent } from './info-footer/info-footer.component';
import { LoaderComponent } from './loader/loader.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { YoutubeComponent } from './youtube/youtube.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { AccordionComponent } from './accordion/accordion.component';
import { AccordionBannerComponent } from './accordion-banner/accordion-banner.component';


import {
  SwiperModule
} from 'ngx-swiper-wrapper';
import { ContactComponent } from './contact/contact.component';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { SeeMoreImgsComponent } from './see-more-imgs/see-more-imgs.component';
import { SwiperSmallComponent } from './swiper-small/swiper-small.component';
import { MenuComponent } from './menu/menu.component';

import {MatSidenavModule} from '@angular/material/sidenav';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatRippleModule} from '@angular/material/core'
import { MatToolbarModule } from '@angular/material/toolbar'; //Nuevo
import { MatIconModule } from '@angular/material/icon'; //Nuevo
import {MatButtonModule} from '@angular/material/button'; //Nuevo
import { TestComponent } from './test/test.component';

import {OverlayContainer} from '@angular/cdk/overlay';
import {CdkOverlayContainer} from '../helpers/cdk-overlay-container.component';
// import { CdkOverlayContainerDirective } from '../helpers/cdk-overlay-container.directive';
import { SwiperWideComponent } from './swiper-wide/swiper-wide.component';
import { InfoFooterSimpleComponent } from './info-footer-simple/info-footer-simple.component';
import { SmallBannerComponent } from './small-banner/small-banner.component';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { PictureComponent } from './picture/picture.component';


@NgModule({
  declarations: [
    HeaderComponent,
    MainBannerComponent,
    SearchBarComponent,
    CardComponent,
    FooterComponent,
    TopFullBannerComponent,
    TopFullBannerRifasComponent,
    TopFullBannerSlideComponent,
    GoogleMapComponent,
    PaqueteExpComponent,
    InfoFooterComponent,
    LoaderComponent,
    ReadMoreComponent,
    YoutubeComponent,
    AccordionComponent,
    AccordionBannerComponent,
    ContactComponent,
    SeeMoreImgsComponent,
    SwiperSmallComponent,
    MenuComponent,
    TestComponent,
    // CdkOverlayContainerDirective,
    SwiperWideComponent,
    InfoFooterSimpleComponent,
    SmallBannerComponent,
    PictureComponent,

  ],
  imports: [CommonModule, AnimateModule, RouterModule, YouTubePlayerModule, SwiperModule, FormsModule, ReactiveFormsModule, MatSidenavModule, MatFormFieldModule, MatSelectModule, MatAutocompleteModule, MatInputModule, MatIconModule, MatRippleModule, MatToolbarModule, MatButtonModule, RecaptchaModule,
  AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB1MalKeRYr9CT8mSLYA0Qy3UwG15tXFlk',
    }),],
  exports: [
    MainBannerComponent,
    HeaderComponent,
    SearchBarComponent,
    CardComponent,
    FooterComponent,
    TopFullBannerComponent,
    TopFullBannerRifasComponent,
    TopFullBannerSlideComponent,
    GoogleMapComponent,
    PaqueteExpComponent,
    InfoFooterComponent,
    LoaderComponent,
    ReadMoreComponent,
    YoutubeComponent,
    AccordionComponent,
    AccordionBannerComponent,
    ContactComponent,
    SeeMoreImgsComponent,
    SwiperSmallComponent,
    MenuComponent,
    TestComponent,
    // CdkOverlayContainerDirective,
    SwiperWideComponent,
    InfoFooterSimpleComponent,
    SmallBannerComponent,
    PictureComponent
  ],
  providers: [{ provide: OverlayContainer, useClass: CdkOverlayContainer }]
})
export class ComponentsModule {}
