<div class="contact-container" *ngIf="showContact">
    <div class="contact-box">
        <app-loader *ngIf="loading" type="small"></app-loader>
        <div class="contact-content" *ngIf="!loading && !successMsg && !errorMsg">
            <!-- <div class="close" (click)="closeContact()">
                <img src="/assets/imgs/close.svg" alt="">
            </div> -->
            <div class="contact-title">{{title}}</div>
            <div class="contact-description">{{description}}</div>
            <div class="contact-input">
                <input placeholder="Nombre" type="text" name="nameTxt" [(ngModel)]="nameTxt">
            </div>
            <div class="contact-input">
                <input placeholder="Teléfono" type="text" name="phoneTxt" [(ngModel)]="phoneTxt">
            </div>
            <div class="contact-input">
                <input placeholder="Mail" type="text" name="mailTxt" [(ngModel)]="mailTxt">
            </div>
            <div class="contact-input">
                <textarea name="msgTxt" placeholder="Mensaje..." cols="" rows="" [(ngModel)]="msgTxt"></textarea>
            </div>
            <div class="recaptcha">
                <recaptcha [(ngModel)]="myRecaptcha"></recaptcha>
            </div>
            <div class="fields-error" *ngIf="fieldsError != ''">
                {{ fieldsError }}
            </div>
            <div class="contact-btn btn-green" (click)="send()">
                {{ btnTxt }}
            </div>
        </div>
        <div class="contact-content contact-message" *ngIf="!loading && successMsg">
            <div class="contact-title">
                Gracias por comunicarte, en breve nos ponemos en contacto!
            </div>
            <div class="contact-btn btn-green" (click)="closeContact()">
                Cerrar
            </div>
        </div>
        <div class="contact-content contact-message" *ngIf="!loading && errorMsg">
            <div class="contact-title">
                Ha ocurrido un error, llamanos al <a href="tel:+59824183898" target="_blank">(+598)&nbsp;2418&nbsp;3898</a> o escribinos por <a href="https://wa.me/59892443898" target="_blank">WhatsApp al (+598)&nbsp;92&nbsp;443&nbsp;898</a>
            </div>
            <div class="contact-btn btn-green" (click)="closeContact()">
                Cerrar
            </div>
        </div>
    </div>
</div>