<div class="accordion-container" [class.accordion-on]="isActive" (click)="toggleAccordian($event)">
    <div class="parent-item">
        <div class="list-item-text">
            <div class="list-item-title">
                {{ data.item.incluyeNombre }}
            </div>
            <div class="list-item-description">
                {{ data.item.incluyeDescripcion }}
            </div>
        </div>
        <div class="list-item-icon">
            <img src="/assets/imgs/icons/{{data.item.incluyeIcono}}.svg" alt="">
        </div>
        <div class="clearfix"></div>
    </div>
    <!-- <button class="accordion" (click)="toggleAccordian($event)"> aaaaaa </button> -->
    <div class="panel" hide="!isActive">
        <div class="list-item" *ngFor="let inc of data.subItem">
            <div class="list-item-text">
                <div class="list-item-title">
                    {{ inc.incluyeitemNombre }}
                </div>
                <div class="list-item-description">
                    {{ inc.incluyeitemDescripcion }}
                </div>
                <div class="list-item-icon" *ngIf="inc.incluyeitemIcono != 'Alojamiento'">
                    <img src="/assets/imgs/icons/{{inc.incluyeitemIcono}}.svg" alt="">
                </div>
                <div class="list-item-icon" *ngIf="inc.incluyeitemIcono == 'Alojamiento'">
                    <img src="/assets/imgs/icons/arrow_right-black.svg" alt="">
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>