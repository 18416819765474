import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { HomeComponent } from './pages/home/home.component';
// import { CalendarComponent } from './pages/calendar/calendar.component';
// import { InternaViajeComponent } from './pages/interna-viaje/interna-viaje.component';
// import { TutoqueComponent } from './pages/tutoque/tutoque.component';
// import { Viajes360Component } from './pages/viajes360/viajes360.component';
import { BusquedaComponent } from './pages/busqueda/busqueda.component';
// import { RifasComponent } from './pages/rifas/rifas.component';
// import { CalendarModule } from './pages/calendar/calendar.module';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    // component: HomeComponent,
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'calendario',
    // component: CalendarComponent,
    loadChildren: () => import('./pages/calendar/calendar.module').then(m => m.CalendarModule)
  },
  {
    path: 'privacidad',
    loadChildren: () => import('./pages/privacidad/privacidad.module').then(m => m.PrivacidadModule)
  },
  {
    path: 'grupos/:viaje',
    // component: InternaViajeComponent,
    loadChildren: () => import('./pages/interna-viaje/interna-viaje.module').then(m => m.InternaViajeModule)
  },
  {
    path: 'tu-toque',
    loadChildren: () => import('./pages/tutoque/tutoque.module').then(m => m.TutoqueModule)
    // component: TutoqueComponent,
  },
  {
    path: 'pasajes360',
    loadChildren: () => import('./pages/viajes360/viajes360.module').then(m => m.Viajes360Module)
    // component: Viajes360Component,
  },
  {
    path: 's',
    component: BusquedaComponent,
  },
  {
    path: 'rifas',
    loadChildren: () => import('./pages/rifas/rifas.module').then(m => m.RifasModule)
    // component: RifasComponent,
  },
  // {
  //   path: 'pais/:country',
  //   component: PaisComponent,
  //   // data: {country: 'india'}
  // },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
