<div class="accordion-container" [class.accordion-on]="isActive">
    <div class="parent-item" (click)="toggleAccordian($event)">
        <!-- <div class="list-item-text">
            <div class="list-item-title">
                {{ data.item.incluyeNombre }}
            </div>
            <div class="list-item-description">
                {{ data.item.incluyeDescripcion }}
            </div>
        </div> -->
        <!-- <div class="list-item-icon">
            <img src="/assets/imgs/icons/car-icon.svg" alt="">
        </div> -->
        <div class="col-1 itinerario-pais">
            <div class="itinerario-pais-img">
                <img [src]="getSquareHorizontalImg(data.pais.paisName)" alt="">
            </div>
            <div class="itinerario-text">
                <div class="itinerario-pais-name">
                    {{ data.pais.paisName }}
                </div>
                <div class="itinerario-cities">
                    <span *ngFor="let ciu of data.ciudades; let i = index">
                        <span *ngIf="i>0"> - </span> {{ ciu.ciudad.ciudadName }}
                    </span>
                </div>
                <div class="itinerario-cant-dias">
                    <span *ngIf="paisCantDias > 1">{{ paisCantDias }} días</span>
                    <span *ngIf="paisCantDias == 1">{{ paisCantDias }} día</span>
                </div>
            </div>
            <div class="itinerario-ver-ciudades btn-standard">
                Ciudades
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
    <!-- <button class="accordion" (click)="toggleAccordian($event)"> aaaaaa </button> -->
    <div class="panel" hide="!isActive">
        <!-- <div class="list-item" *ngFor="let inc of data.subItem"> -->

        <div class="row wide">
            <div class="swiper-box" *ngIf="data.ciudades.length > 3">
                <swiper [config]="config">
                    <div class="swiper-slide ciudad-item" *ngFor="let ciu of data.ciudades">
                        <img appSlug [src]="getSquareSmallImg(ciu.ciudad.ciudadName)" alt="">
                        <div class="city">
                            {{ ciu.ciudad.ciudadName }}
                        </div>
                        <!-- <div class="country">
                    {{ ciu.ciudad.pais.paisName }}
                </div> -->
                        <div class="days">
                            <span *ngIf="ciu.itinerariociudadCantdias > 1">{{ ciu.itinerariociudadCantdias }} días</span>
                            <span *ngIf="ciu.itinerariociudadCantdias == 1">{{ ciu.itinerariociudadCantdias }} día</span>
                        </div>
                    </div>
                </swiper>
                <!-- <div class="swiper-custom-prev swiper-custom-arrow "></div>
        <div class="swiper-custom-next swiper-custom-arrow "></div> -->
                <div class="clearfix "></div>
                <div class="swiper-custom-pagination "></div>
            </div>
            <div *ngIf="data.ciudades.length <= 3">
                <div class="ciudad-item col-3" *ngFor="let ciu of data.ciudades">
                    <img appSlug [src]="getSquareSmallImg(ciu.ciudad.ciudadName)" alt="">
                    <div class="city">
                        {{ ciu.ciudad.ciudadName }}
                    </div>
                    <!-- <div class="country">
                    {{ ciu.ciudad.pais.paisName }}
                </div> -->
                    <div class="days">
                        <span *ngIf="ciu.itinerariociudadCantdias > 1">{{ ciu.itinerariociudadCantdias }} días</span>
                        <span *ngIf="ciu.itinerariociudadCantdias == 1">{{ ciu.itinerariociudadCantdias }} día</span>
                    </div>
                </div>
            </div>


            <!-- <div class="col-3 multi" *ngFor="let ciu of data.ciudades">
                <img appSlug [src]="getSquareSmallImg(ciu.ciudad.ciudadName)" alt="">
                <div class="city">
                    {{ ciu.ciudad.ciudadName }}
                </div>
                <div class="country">
                    {{ ciu.ciudad.pais.paisName }}
                </div>
                <div class="days">
                    <span *ngIf="ciu.itinerariociudadCantdias > 1">{{ ciu.itinerariociudadCantdias }} días</span>
                    <span *ngIf="ciu.itinerariociudadCantdias == 1">{{ ciu.itinerariociudadCantdias }} día</span>
                </div>
            </div> -->
        </div>
    </div>
</div>