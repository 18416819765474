import { Component, Input, OnInit } from '@angular/core';

import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-accordion-banner',
  templateUrl: './accordion-banner.component.html',
  styleUrls: ['./accordion-banner.component.scss']
})
export class AccordionBannerComponent implements OnInit {

  @Input() data;
  public paisCantDias = 0;
  public isActive = false;

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 3,
    spaceBetween: 8,
    // freeMode: false,
    // centeredSlides: false,
    breakpoints: {
      // 799: {
      //   slidesPerView: 6.2,
      //   spaceBetween: 10,
      // },
      // 1200: {
      //   slidesPerView: 8.2,
      //   spaceBetween: 10,
      // },
      // 1600: {
      //   slidesPerView: 10.2,
      //   spaceBetween: 10,
      // },
    },
    // navigation: false,
    // pagination: false,
  };

  constructor() { }

  ngOnInit(): void {
    this.data.ciudades.forEach(element => {
      this.paisCantDias += element.itinerariociudadCantdias;
    });
  }

  toggleAccordian(event) {
    const element = event.target;
    element.classList.toggle('active');

    if (this.isActive) {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
    const panel = element.parentElement.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }

  getSquareSmallImg(ciudadName) {
    let str = ciudadName;


    // let lTxt = pTxt;
    str = str.toLowerCase();

    // lTxt = lTxt.replaceAll()
    


    str = str
    .toLowerCase()
    .replace(/á/gi, 'a')
    .replace(/é/gi, 'e')
    .replace(/í/gi, 'i')
    .replace(/ó/gi, 'o')
    .replace(/ú/gi, 'u')
    .replace(/ñ/gi, 'n')
    .split(' ').join('_');
    
    return '/assets/imgs/grupos/square-small/'+ str + '.png';
  }
  
  getSquareHorizontalImg(paisName) {
    let str = paisName
    .toLowerCase()
    .replace(/á/gi, 'a')
    .replace(/é/gi, 'e')
    .replace(/í/gi, 'i')
    .replace(/ó/gi, 'o')
    .replace(/ú/gi, 'u')
    .replace(/ñ/gi, 'n')
    .split(' ').join('_');

    return '/assets/imgs/grupos/square-horizontal/'+ str + '.png';
  }
}
