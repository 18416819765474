import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  @Input() showContact = false;
  @Output() contactState = new EventEmitter();
  public nameTxt;
  public phoneTxt;
  public mailTxt;
  public msgTxt;
  public successMsg = false;
  public errorMsg = false;
  public myRecaptcha = false;
  public fieldsError = '';
  public loading = false;
  @Input() title;
  @Input() viaje;
  @Input() description;
  @Input() btnTxt = 'Enviar consulta';

  constructor(
    private contactService: ContactService,
    public globalConstants: GlobalConstants
  ) { }

  closeContact() {
    this.showContact = false;
    this.contactState.emit( this.showContact );
    this.successMsg = false;
    this.errorMsg = false;
  }

  send() {
    this.fieldsError = '';
    if( this.nameTxt != '' && this.mailTxt != '' && this.phoneTxt != '' && this.msgTxt != '' && this.globalConstants.validateEmail( this.mailTxt ) && this.myRecaptcha ) {
      this.loading = true;
      this.contactService.sendContactGrupo(this.nameTxt, this.phoneTxt, this.mailTxt, this.viaje, this.msgTxt).subscribe( (res:any) => {
        this.loading = false;
        if (res.status == 'success') {
          this.successMsg = true;
        } else {
          this.errorMsg = true;
        }
      });
    } else {
      this.fieldsError = '*Debes completar todos los campos.';
    }
  }

}
