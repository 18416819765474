import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { GlobalConstants } from './common/global-constants';
// import{ GlobalConstants } from './common/global-constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'De Toque y Toque';

  // public showFloatingFooter = false;
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public globalConstants: GlobalConstants
    ) {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    if (isPlatformBrowser(this.platformId)) {
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  @HostListener('window:scroll', ['$event'])
    doSomething(event) {
    if (isPlatformBrowser(this.platformId)) {
      if (window.pageYOffset > 150) {
        this.globalConstants.setWhatsappBtn(true);
        if (
          window.innerHeight + window.scrollY + 300 >=
          document.body.offsetHeight
        ) {
          this.globalConstants.setWhatsappBtn(false);
        } else {
          this.globalConstants.setWhatsappBtn( true );
        }
      } else {
        this.globalConstants.setWhatsappBtn(false);
      }
    }
  }

}
