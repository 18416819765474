<app-loader *appShellRender></app-loader>

<router-outlet></router-outlet>
<!-- <div class="floating-footer" *ngIf="showFloatingFooter">
    <div class="wp-button">
        <a href="https://wa.me/59892003898" target="_blank">
            <img src="/assets/imgs/wp.png" alt="">
        </a>
    </div>
</div> -->
<div class="btn-right whatsapp-btn" *ngIf="globalConstants.getWhatsappBtn()">
    <a href="https://wa.me/59892443898" target="_blank">
        <img src="/assets/imgs/wp-icon.svg" alt="">
    </a>
</div>