import { Injectable } from "@angular/core";
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Injectable()
export class GlobalConstants {
    private showWhatsappBtn: boolean = false;
    private pageWithWhatsappBtn: boolean = false;
    public titlePrefix: string = 'de Toque y Toque | ';
    public swiperConfig: SwiperConfigInterface = {
        direction: 'horizontal',
        slidesPerView: 1.3,
        centeredSlides: false,
        spaceBetween: 10,
        slidesOffsetAfter: 100,
        observer: true,
        breakpoints: {
          640: {
            slidesPerView: 1.3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4.1,
            spaceBetween: 20,
            centeredSlides: false,
          },
        },
        navigation: {
          nextEl: '.swiper-custom-next',
          prevEl: '.swiper-custom-prev',
        },
        pagination: true,
      };

    private responsiveImagesConfiguration = [
        {
          min: 0,
          max: 479,
          suffix: '_xs',
          format: 'webp',
        },
        {
          min: 0,
          max: 479,
          suffix: '_xs',
          format: 'png',
        },
        {
          min: 480,
          max: 768,
          suffix: '_sm',
          format: 'webp',
        },
        {
          min: 480,
          max: 768,
          suffix: '_sm',
          format: 'png',
        },
        {
          min: 769,
          max: 1024,
          suffix: '_md',
          format: 'webp',
        },
        {
          min: 769,
          max: 1024,
          suffix: '_md',
          format: 'png',
        },
        {
          min: 1025,
          max: 1200,
          suffix: '_lg',
          format: 'webp',
        },
        {
          min: 1025,
          max: 1200,
          suffix: '_lg',
          format: 'png',
        },
        {
          min: 1201,
          max: 5000,
          suffix: '_xl',
          format: 'webp',
        },
    
        {
          min: 1201,
          max: 5000,
          suffix: '_xl',
          format: 'png',
        },
    ];

    setWhatsappBtn( pValue ) {
        this.showWhatsappBtn = pValue;
    }
    getWhatsappBtn() {
        if( !this.getPageWithWhatsappBtn() ) {
            return false
        };
        return this.showWhatsappBtn;
    }
    
    getSwiperConfig() {
        return this.swiperConfig;
    }
    
    getTitlePrefix() {
        return this.titlePrefix;
    }
    
    getResponsiveImagesConfiguration() {
        return this.responsiveImagesConfiguration;
    }
    
    setPageWithWhatsappBtn( pValue ) {
        this.pageWithWhatsappBtn = pValue;
    }
    getPageWithWhatsappBtn() {
        return this.pageWithWhatsappBtn;
    }

    validateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
          return true;
        }
        return false;
    }
}